<script setup>
import { useQuoteStore } from '@/stores/quote';
import {useUserStore} from "@/stores/user";
const quoteStore = useQuoteStore();
const userStore = useUserStore();
</script>

<template>
  <footer>
    <div class="container">
      <div class="d-flex py-7 fs-6 text-n600">
        <div class="link-item">
          <a
            class="footer-link"
            href="/privacy"
            target="_blank"
            >Πολιτική Απορρήτου</a
          >
        </div>
        <div class="link-item">
          <a class="footer-link"
             :href = "userStore.isIm() ? '/terms' : '/terms-and-conditions'"
             target="_blank"
             >Όροι Χρήσης</a
          >
        </div>
        <div class="link-item">
          <a
            class="footer-link"
            href="/cookie-policy"
            target="_blank"
            >Πολιτική Cookies</a
          >
        </div>
      </div>
      <div v-if="userStore.isIm() && !userStore.isPanelIFrame()" class="fs-6 text-n600 mb-5">
        Copyright © {{ new Date().getFullYear() }} insurancemarket.gr | Version: {{ version }}
      </div>
      <div v-if="userStore.isIm() && !userStore.isPanelIFrame()" class="py-4 fs-6 text-n600 mb-4 border-2 border-top border-bottom">
        Το insurancemarket.gr αναπτύχθηκε από την Money Market Α.Ε. με Α.Φ.Μ 998940997/ΦΑΕ Αθηνών και αριθμό ΓΕΜΗ 006784301000, που εδρεύει επί της οδού Ευριπίδου 31-33 στην Αθήνα και έχει νομίμως καταχωρηθεί στο Μητρώο του Επαγγελματικού Επιμελητηρίου Αθηνών ως ασφαλιστικός πράκτορας με αριθμό μητρώου 6584 και ως συντονιστής ασφαλιστικών πρακτόρων με αριθμό μητρώου 1056. Τα ανωτέρω στοιχεία μπορείτε να τα επιβεβαιώσετε από το σύνδεσμο του <a href="https://insuranceregistry.uhc.gr/">Ενιαίου Σημείου Πληροφόρησης</a>
      </div>
    </div>
    <!-- build version -->
  </footer>
</template>
